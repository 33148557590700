import { scrollIntoView } from 'scroll-js';

// intersection observer setup
// const observerOptions = {
//     root: null,
//     rootMargin: '0px',
//     threshold: 0.7,
// };

const navItems = document.querySelectorAll('.js-nav-items');
const sections = document.querySelectorAll('.js-section');

Object.values(navItems).forEach( el => {
    el.addEventListener('click', () => {
        const target = document.getElementById(el.dataset.target);
        scrollIntoView(target, document.body, { behavior: 'smooth' });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 
          'event': 'onpage_nav',
          'title' : el.dataset.title,
          'selection' : el.dataset.selection
        });
    });
});

function observerCallback(entries) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const active = document.querySelector(`.js-nav-items[data-target="${entry.target.dataset.section}"]`);
            if (active) {
                active.classList.add('border-b');
            }
            
            Object.values(navItems).forEach((item) => {
                if (item != active) {
                    item.classList.remove('border-b');
                }
            });
        }
    });
}

window.addEventListener(
    'load',
    () => {
        const observer = new IntersectionObserver(observerCallback, {
            threshold: 0.1
        });

        sections.forEach((section) => observer.observe(section));
    },
    false
);
