/* eslint-disable no-unused-vars */
import Swiper, { Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

const showreel = document.querySelector('.showreel');

const swiper = new Swiper(showreel, {
    modules: [Autoplay, EffectFade],
    loop: true,
    slidesPerView: 1,
    autoplay: {
        delay: 300
    },
    effect: 'fade',
    fadeEffect: { crossFade: true }
});
