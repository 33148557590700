function replaceText(node) {
    const replacements = [
        { word: /©/gi, replacement: '<sup>&copy;</sup>' },
        { word: /™️/gi, replacement: '<sup>&trade;</sup>' },
        { word: /®️/gi, replacement: '<sup>&reg;</sup>' }
    ];

    // Skip script and style tags
    if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName === 'SCRIPT' || node.tagName === 'STYLE') {
            return;
        }
    }

    if (node.nodeType === Node.TEXT_NODE) {
        let content = node.textContent;
        replacements.forEach(({ word, replacement }) => {
            content = content.replace(word, replacement);
        });

        if (content !== node.textContent) {
            const newSpan = document.createElement('span');
            newSpan.innerHTML = content;
            node.parentNode.replaceChild(newSpan, node);
        }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(replaceText);
    }
}

replaceText(document.body);
