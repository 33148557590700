import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/video-embed/ls.video-embed';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.start();

import '@/scripts/utils/gtm';
import '@/scripts/utils/superscript';

import '@/scripts/components/autoplay';
import '@/scripts/components/back-to-top';
import '@/scripts/components/cart';
import '@/scripts/components/doc-height';
import '@/scripts/components/image-carousel';
import '@/scripts/components/modal';
import '@/scripts/components/one-page-nav';
import '@/scripts/components/product';
import '@/scripts/components/smooth';
import '@/scripts/components/scrollspy';
import '@/scripts/components/venue-tv';
import '@/scripts/components/textarea';
import '@/scripts/components/waitlist';
import '@/scripts/components/scroll';
import '@/scripts/components/showreel';
import '@/scripts/components/size-table';
import '@/scripts/components/states';

// Leave as last one
import '@/styles/app.css';

import '@/vue/init';
