const items = document.querySelectorAll('.js-gtm');

Array.from(items).forEach( el => {
    el.addEventListener('click', (e) => {
    	e.preventDefault();

    	const url = el.href || null;
    	const dataset = { ...el.dataset };

    	window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(dataset);

		if (url) {
			const target = el.getAttribute('target') || '_self';
			window.open(url, target || '_self');
		}
    });
});

// Contact & subscribe form
const form = document.querySelector('.js-gtm-form form');
if (form) {
	const data = JSON.parse(form.dataset.fuiForm);

	if (data.formHandle === 'contact') {
		form.addEventListener('onAfterFormieSubmit', () => {
		    window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: 'send_enquiry' });
		});
	}

	if (data.formHandle === 'signUp') {
		form.addEventListener('onAfterFormieSubmit', () => {
		    window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: 'email_sign_up' });
		});
	}
}

// Shop page
const shop = document.querySelector('.js-shop');
if (shop) {

	const ecommerceItems = shop.querySelectorAll('.js-gtm-ecommerce');
	const items = [];

	Array.from(ecommerceItems).forEach( el => {
		const dataset = { ...el.dataset };
		items.push(dataset);
	});

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			items
		}
	});
}
if (shop) {
	shop.addEventListener('click', (e) => {
		if (e.target.closest('.shop__categories')) {
			setTimeout(() => {
				const ecommerceItemsCl = shop.querySelectorAll('.js-gtm-ecommerce');
				const items = [];

				Array.from(ecommerceItemsCl).forEach( el => {
					const dataset = { ...el.dataset };
					items.push(dataset);
				});

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: 'view_item_list',
					ecommerce: {
						items
					}
				});
			}, 1000)
		}
		if (e.target.closest('.shop__pagination-link')) {
			setTimeout(() => {
				const ecommerceItemsCl = shop.querySelectorAll('.js-gtm-ecommerce');
				const items = [];

				Array.from(ecommerceItemsCl).forEach( el => {
					const dataset = { ...el.dataset };
					items.push(dataset);
				});

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ ecommerce: null });
				window.dataLayer.push({
					event: 'view_item_list',
					ecommerce: {
						items
					}
				});
			}, 1000)
		}
		if (e.target.closest('a.js-gtm-ecommerce')) {
			setTimeout(() => {
				let clickedItem = e.target.closest('a.js-gtm-ecommerce');
				const url = clickedItem.href || null;
				const dataset = {...clickedItem.dataset};

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ecommerce: null});
				window.dataLayer.push({
					event: 'select_item',
					ecommerce: {
						items: [dataset]
					}
				});

				if (url) {
					window.location.href = url;
				}
			}, 1000)
		}
	})

}
