const triggers = document.querySelectorAll('.js-scroll-trigger');

Array.from(triggers).forEach((el) => {
    el.addEventListener('click', () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    });
});
