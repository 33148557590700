const backToTop = document.querySelector('.js-back-to-top');

if (backToTop) {
    backToTop.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });

    window.addEventListener('scroll', () => {
        backToTop.style.display = window.scrollY > 1500 ? 'flex' : 'none';
    });
}
