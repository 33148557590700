/* eslint-disable no-unused-vars */
import Swiper, { Pagination, Autoplay } from 'swiper';
import 'swiper/css';

const swiper = document.querySelector('.venue-tv-swiper');

if (swiper) {
	new Swiper(swiper, {
        modules: [Pagination, Autoplay],
        loop: true,
        speed: Number(swiper.dataset.speed) || 600,
    	slidesPerView: 1,
        autoplay: {
           delay: Number(swiper.dataset.delay) || 10000,
           disableOnInteraction: false,
         },
        pagination: {
            el: swiper.querySelector('[data-pagination]') || null
        }
    });
}