import Alpine from 'alpinejs';

Alpine.store('scrollSpy', {
    active: null,
    visible: false,
    darkMode: false,

    setDarkMode(value) {
        this.darkMode = value;
    },

    scrollTo(id) {
        const target = document.getElementById(id);
        window.scrollTo({
            top: target.offsetTop,
            behavior: 'smooth'
        });
    },

    setActive(id) {
        this.active = id;
    },

    toggleVisible(value) {
        this.visible = value;
    }
});
